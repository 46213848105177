import React from "react";
import { Provider } from "react-redux";
import { getDefaultConfiguratorOptionSet } from "tsi-common-react/src/apps/configurator/utils";
import { IOptionCode } from "tsi-common-react/src/models/catalogue.interfaces";
import {
    isoImageURL,
    isoProductID,
} from "tsi-common-react/src/models/nominals";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { strToBool } from "tsi-common-react/src/utils/format";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

import { rehydratingStore, store } from "../store";
import * as pdpModels from "./models.interfaces";

const ENABLE_ECOM = strToBool(getPageSetting("enable-ecom", "false"));

/**
 * Load CMS data and the initially selected product variant
 */
// let cmsData: ICMSData | null = null;
let cmsData: pdpModels.IProductPage | null = null;
const detailContainerElem = document.querySelector<HTMLDivElement>(
    "#product-detail-container[data-cms]",
);
if (detailContainerElem) {
    // Load PDP CMS data
    cmsData = JSON.parse(detailContainerElem.dataset.cms || "");
}
// Add attribute to fix inconsitent anchoring to PDP reviews
const reviewsElem = "reviews__body";
const hash = urls.getHash(reviewsElem);
if (hash) {
    onDOMContentLoaded.on(async () => {
        document.body.setAttribute("scrollToReviews", hash.toString());
    });
}
/**
 * Render Configurators
 */
dynamicPlaceComponent(
    '[data-place-react="modular-configurator"]',
    async (elem) => {
        const { ModularConfigurator } = await import(
            "tsi-common-react/src/apps/configurator/containers/ModularConfigurator"
        );
        const { ChatLink } = await import(
            "tsi-common-react/src/apps/chat/ChatLink"
        );
        const { PDPAnalytics } = await import("./containers/PDPAnalytics");
        const phoneNumber = elem.dataset.phoneNumber;
        await rehydratingStore;
        return (
            <Provider store={store}>
                <PDPAnalytics />
                <ModularConfigurator
                    optionSetJSON={
                        elem.dataset.configuratorInitData ||
                        JSON.stringify(getDefaultConfiguratorOptionSet())
                    }
                    passthroughProps={{
                        actualPriceStyle: "",
                        boxHeaderContent: "",
                        buttonColor: "primary-congress-blue",
                        configType: "pdp-hero",
                        description: cmsData?.description
                            ? cmsData.description
                            : "",
                        enableHistoryInteraction: true,
                        enableAddToFavorites: !ENABLE_ECOM,
                        getDeliveryCopy: () => {
                            return cmsData && cmsData.shipping_description
                                ? cmsData.shipping_description
                                : "";
                        },
                        hideLearnMoreLink: true,
                        liveChatHeader: cmsData ? cmsData.live_chat_header : "",
                        showFeelScale: true,
                        showImagePerUpgradeOption: true,
                        showPreTitle: false,
                        showShippingLead: !!(
                            cmsData && cmsData.show_shipping_lead
                        ),
                        showUpsellModalIcon: true,
                        showVariantCallout: false,
                        showStarRatingsModal: true,
                        starRatingURL: urls.pageURL("reviews-link"),
                        strikeThroughMSRP: false,
                        supDollarSign: false,
                        title: cmsData ? cmsData.title : "",
                        preheader: cmsData ? cmsData.preheader : "",
                        subhead: cmsData ? cmsData.subhead : "",
                        topBadgeContent: "",
                        phoneNumber: phoneNumber,
                        chatLinkComponent: (
                            <ChatLink
                                className={"button"}
                                chatOnlineText={t`Chat`}
                                chatOfflineLink={urls.pageURL("contact-link")}
                            />
                        ),
                    }}
                />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render ChangeVariantOptionBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-change-variant-option-block"]',
    async (elem) => {
        const { PDPChangeVariantOptionBlock } = await import(
            "./containers/PDPChangeVariantOptionBlock"
        );
        const attributeCode = (elem.dataset.attributeCode ||
            "option_feel") as IOptionCode;
        return (
            <Provider store={store}>
                <PDPChangeVariantOptionBlock attributeCode={attributeCode} />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render SelectedVariantConditionalBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-selected-variant-conditional-block"]',
    async (elem) => {
        const { PDPSelectedVariantConditionalBlock } = await import(
            "./containers/PDPSelectedVariantConditionalBlock"
        );
        const attributeCode = (elem.dataset.attributeCode ||
            "option_feel") as IOptionCode;
        const attributeValue = elem.dataset.attributeValue || "";
        const htmlContent = elem.innerHTML || "";
        // Show the root element
        elem.style.display = "block";
        return (
            <Provider store={store}>
                <PDPSelectedVariantConditionalBlock
                    attributeCode={attributeCode}
                    attributeValue={attributeValue}
                    content={htmlContent}
                />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render GalleryBlock
 */
dynamicPlaceComponent('[data-place-react="gallery-block"]', async (elem) => {
    const { GalleryBlock } = await import(
        "tsi-common-react/src/apps/gallery/components/GalleryBlock"
    );
    return (
        <Provider store={store}>
            <GalleryBlock
                galleriesJSON={elem.dataset.galleries || "{}"}
                defaultThumbnail={isoImageURL.wrap(
                    elem.dataset.videoThumbnail || "",
                )}
            />
        </Provider>
    );
}).catch(console.error);

/**
 * Render PDPChangeRootProductBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-change-root-product-block"]',
    async () => {
        const { PDPChangeRootProductBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPChangeRootProductBlock"
        );
        return (
            <Provider store={store}>
                <PDPChangeRootProductBlock />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render SelectedRootProductConditionalBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-selected-root-product-conditional-block"]',
    async (elem) => {
        const { PDPSelectedRootProductConditionalBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPSelectedRootProductConditionalBlock"
        );
        const productID = isoProductID.wrap(
            parseInt(elem.dataset.product || "0", 10),
        );
        const htmlContent = elem.innerHTML || "";
        // Show the root element
        elem.style.display = "block";
        return (
            <Provider store={store}>
                <PDPSelectedRootProductConditionalBlock
                    blockProductID={productID}
                    content={htmlContent}
                />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render EmailCaptureBlock
 */
dynamicPlaceComponent(
    '[data-place-react="email-capture-block"]',
    async (elem) => {
        const { EmailCaptureContent } = await import(
            "tsi-common-react/src/apps/common/containers/EmailCaptureContent"
        );
        const cms_data = JSON.parse(elem.dataset.cms || "null");

        return <EmailCaptureContent content={cms_data} />;
    },
).catch(console.error);

/**
 * Render compatibility module
 */
dynamicPlaceComponent('[data-place-react="compatibility"]', async (elem) => {
    const { CompatibilityAccordion } = await import(
        "tsi-common-react/src/blocks/components/CompatibilityAccordion"
    );
    const main = JSON.parse(elem.dataset.cms || "");

    return (
        <CompatibilityAccordion
            cms={main}
            contentPanels={main.compatibility_panels.panels}
        />
    );
}).catch(console.error);
