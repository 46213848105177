import React from "react";
import SVG from "react-inlinesvg";
import { CartReferenceNumber } from "tsi-common-react/src/apps/checkout/CartReferenceNumber";
import { jt, t } from "ttag";

import { templates } from "@reactivated";

import logo from "../src/svg/logo.svg";

export const Template = (
    props: templates.CheckoutHeaderTemplate,
): React.ReactNode => {
    const secure = (
        <span
            key="secure"
            className="checkout-header__header--bold"
        >{t`secure`}</span>
    );
    const secureCheckout = jt`${secure} checkout`;
    return (
        <header className="checkout-header">
            <a className="logo-link" href={props.root_page_url}>
                <SVG
                    className="logo header-svg"
                    src={logo}
                    title={t`Stearns & Foster Logo`}
                />
            </a>

            <h1 className="checkout-header__header">{secureCheckout}</h1>

            <div className="checkout-header__header-cart-ref">
                <CartReferenceNumber
                    encodedBasketID={props.encoded_basket_id}
                    phoneNumber={props.support_website_phone_number}
                />
            </div>
        </header>
    );
};
