import React from "react";
import { Provider } from "react-redux";
import { snapUserDataLayerState } from "tsi-common-react/src/api/datalayer";
import { registerCascades } from "tsi-common-react/src/apps/common/cascades";
import { Dispatchers } from "tsi-common-react/src/apps/common/dispatchers";
import { Loaders } from "tsi-common-react/src/apps/common/loaders";
import { initCSRToolbar } from "tsi-common-react/src/apps/csr";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { strToBool } from "tsi-common-react/src/utils/format";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { urls } from "tsi-common-react/src/utils/urls";

import { rehydratingStore, store } from "../store";

export const dispatchers = new Dispatchers(store.dispatch);
export const loaders = new Loaders(dispatchers);

// Register store cascades
registerCascades(store);

// Render account menu
dynamicPlaceComponent('[data-place-react="auth-login-menu-link"]', async () => {
    const { AccountMenu } = await import(
        "tsi-common-react/src/apps/authentication/components/AccountMenu"
    );
    return (
        <Provider store={store}>
            <AccountMenu />
        </Provider>
    );
}).catch(console.error);

// Render standalone login forms
dynamicPlaceComponent(
    '[data-place-react="auth-login-standalone"]',
    async (elem) => {
        const { LoginForm } = await import(
            "tsi-common-react/src/apps/authentication/components/LoginForm"
        );
        const nextURL = elem.dataset.continueUrl
            ? isoWebPageURL.wrap(elem.dataset.continueUrl)
            : undefined;
        return <LoginForm nextURL={nextURL} />;
    },
).catch(console.error);

// Render Session Keep-Alive Modal
dynamicPlaceComponent(
    '[data-place-react="session-keep-alive-modal"]',
    async () => {
        const { SessionKeepAlive } = await import(
            "tsi-common-react/src/apps/authentication/components/SessionKeepAlive"
        );
        return <SessionKeepAlive />;
    },
).catch(console.error);

// Render Value Props Bar
dynamicPlaceComponent('[data-place-react="value-props-bar"]', async (elem) => {
    const { ValuePropsBar } = await import(
        "tsi-common-react/src/common/ValuePropsBar"
    );
    const parsedData = JSON.parse(elem.dataset.cms || "");
    return <ValuePropsBar valueProps={parsedData.value_props} />;
}).catch(console.error);

// Render Service Footer
dynamicPlaceComponent('[data-place-react="service-footer"]', async (elem) => {
    const { ServiceFooter } = await import(
        "tsi-common-react/src/apps/common/containers/ServiceFooter"
    );
    const snippets = JSON.parse(elem.dataset.snippets || "null");
    const interval = Number(elem.dataset.interval) || null;
    const loops = Number(elem.dataset.loops) || null;

    return (
        <Provider store={store}>
            <ServiceFooter
                snippets={snippets.snippets}
                interval={interval}
                loops={loops}
            />
        </Provider>
    );
}).catch(console.error);

dynamicPlaceComponent('[data-place-react="footer-widgets"]', async (elem) => {
    const { FooterWidgets } = await import(
        "tsi-common-react/src/apps/common/containers/FooterWidgets"
    );
    return (
        <Provider store={store}>
            <FooterWidgets
                phoneNumber={elem.dataset.phoneNumber || ""}
                showContactSupportWidget={strToBool(
                    elem.dataset.showContactSupport || "",
                )}
                showNearbyStoresWidget={strToBool(
                    elem.dataset.showNearbyStores || "",
                )}
            />
        </Provider>
    );
}).catch(console.error);

// Place Live Chat Links
dynamicPlaceComponent('[data-place-react="chat-link"]', async (elem) => {
    const { ChatLink } = await import(
        "tsi-common-react/src/apps/chat/ChatLink"
    );
    return (
        <ChatLink
            className={elem.dataset.chatLinkClass}
            chatOnlineText={elem.dataset.chatOnlineText}
            chatOfflineText={elem.dataset.chatOfflineText}
            chatOfflineLink={
                elem.dataset.chatOfflineLink
                    ? isoWebPageURL.wrap(elem.dataset.chatOfflineLink)
                    : urls.pageURL("customer-service")
            }
        />
    );
}).catch(console.error);

// Render warranty form
dynamicPlaceComponent('[data-place-react="warranty-form"]', async (elem) => {
    const { WarrantyForm } = await import(
        "tsi-common-react/src/common/WarrantyForm"
    );
    const buttons =
        document.querySelectorAll<HTMLAnchorElement>("a[class='button']");
    if (buttons) {
        const claimBtn = Array.from(buttons).find((el) => {
            return el.innerText.toLowerCase().includes("claim");
        });

        if (claimBtn) {
            claimBtn.removeAttribute("href");
        }
    }

    const content = JSON.parse(elem.dataset.cms || "");
    const url =
        "https://help.stearnsandfoster.com/hc/en-us/requests/new?ticket_form_id=1500003170642";
    return (
        <WarrantyForm
            cms={content}
            propertyName="Stearns & Foster®"
            claimURL={url}
        />
    );
}).catch(console.error);

// Render user data layer element
dynamicPlaceComponent('[data-place-react="user-data-layer"]', async (elem) => {
    await readyStateComplete;
    // Pause for 1 second to allow the data layer push scripts to run after readyState is complete
    await (async () => {
        return new Promise((res) => {
            setTimeout(res, 1000);
        });
    })();
    const orderID = elem.dataset.order || "";
    await snapUserDataLayerState(orderID);
    return null;
}).catch(console.error);

// Render the CSR toolbar
initCSRToolbar({
    rehydratingStore,
    loaders,
    store,
}).catch(console.error);
