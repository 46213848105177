import { FinancingModalTriggerMinicart } from "@client/src/Checkout/components/FinancingModalTriggerMinicart";
import { RetailLocatorStoreCount } from "@client/src/RetailLocator/containers/RetailLocatorStoreCount";
import React, { memo, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { Provider } from "react-redux";
import { AccountMenu } from "tsi-common-react/src/apps/authentication/components/AccountMenu/index";
import { BasketLineVariant } from "tsi-common-react/src/apps/checkout/constants";
import { BasketMenuIcon } from "tsi-common-react/src/apps/checkout/containers/BasketMenuIcon";
import { RichNavigation } from "tsi-common-react/src/apps/rich-navigation/RichNavigation";
import { ValueProps } from "tsi-common-react/src/apps/rich-navigation/RichNavigationLinkCollection";
import Link from "tsi-common-react/src/common/Link";
import {
    IWebPageURL,
    isoWebPageURL,
} from "tsi-common-react/src/models/nominals";
import { strToBool } from "tsi-common-react/src/utils/format";
import { useCurrentBreakpoint } from "tsi-common-react/src/utils/hooks";
import {
    getPageSetting,
    isPageInsideIFrame as isPageInsideIFrameCheck,
} from "tsi-common-react/src/utils/settings";
import { useIsSSR } from "tsi-common-react/src/utils/ssr";
import urls from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

import {
    CmsNavFeature,
    NavList_16E9563B6D,
    RichNavigationC5Fd9Ac9C3,
    templates,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import {
    classNames,
    concatClassNames,
} from "@thelabnyc/thelabui/src/utils/styles";

import config from "../../../../libs/tsi-common-react/src/config";
import brandLogo from "../../../tsi-stearns/client/src/svg/logo.svg";
import pinIcon from "../../../tsi-stearns/client/src/svg/pin.svg";
import hamburgerIcon from "../../static/img/nav/hamburger-icon.svg";
import { FavoritesIcon } from "../src/Favorites/FavoritesIcon";
import { store } from "../src/store";
import arrowRight from "../src/svg/arrow-right.svg";
import closeIcon from "../src/svg/close.svg";

import styles from "./SiteHeaderTemplate.module.scss";

type BreakpointState = {
    [query: string]: boolean;
};

interface IMenu {
    rich_navigation: RichNavigationC5Fd9Ac9C3 | null;
    nav_features: CmsNavFeature;
    nav_list: NavList_16E9563B6D;
    viewport: BreakpointState;
    menuRef: React.RefObject<HTMLElement>;
    isMenuOpen: boolean;
}

interface IBasketMenuIconProps {
    basketURL: IWebPageURL;
    checkoutURL: IWebPageURL;
    isPageInsideIFrame: boolean;
    enableBasketPrequalSDK: boolean;
    isSSR: boolean;
}

const NavFeature = memo(({ nav_features }: { nav_features: CmsNavFeature }) => {
    return (
        <nav className="feature">
            {nav_features.value?.map((block, i) => (
                <React.Fragment key={`key-${i}`}>
                    {block?.value?.map((feature, index) => {
                        return (
                            <a href={feature?.page?.url} key={`key-${index}`}>
                                {feature.image && (
                                    <ImageChooserBlock
                                        attrs={{ className: styles.featureImg }}
                                        value={feature.image}
                                    />
                                )}
                                {feature.badge && (
                                    <div
                                        className={`feature-badge ${feature.badge?.position ? `feature-badge--${feature.badge?.position}` : ""}`}
                                    >
                                        <div className="feature-badge__image-container">
                                            {feature.badge.image?.url && (
                                                <img
                                                    src={
                                                        feature.badge.image.url
                                                    }
                                                    alt="Feature Badge Image"
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                <span>
                                    {feature.label}
                                    <SVG
                                        className={styles.headerSvg}
                                        src={arrowRight}
                                        title="arrow right icon"
                                    />
                                </span>
                            </a>
                        );
                    })}
                </React.Fragment>
            ))}
        </nav>
    );
});

const NavList = memo(({ nav_list }: { nav_list: NavList_16E9563B6D }) => {
    return (
        <nav className="nav-list">
            {!nav_list.hide_heading && (
                <h5>
                    <button
                        className="accordion-trigger --is-active"
                        aria-controls="accordion-{{ value.heading|slugify }}"
                        aria-expanded="true"
                    >
                        {nav_list.heading}
                    </button>
                </h5>
            )}

            <div
                className="accordion-target"
                id="accordion-{{ value.heading|slugify }}"
                aria-hidden="false"
            >
                {nav_list.body.value &&
                    nav_list.body.value.map((item) => {
                        if (item.type !== "html") {
                            if (!item.value) {
                                return null;
                            }
                            // Check all Link types for page and integrate page.url
                            // Otherwise look for external_url of LinkBlock
                            const url = item.value.page
                                ? item.value.page.url
                                : item.type === "link" &&
                                  item.value.external_url;
                            return (
                                <a
                                    href={url || "/"}
                                    target="_top"
                                    key={item.id}
                                >
                                    {item.value.display_name}
                                </a>
                            );
                        }
                        return (
                            <div
                                key={item.id}
                                dangerouslySetInnerHTML={{ __html: item.value }}
                            />
                        );
                    })}
            </div>
        </nav>
    );
});

const MenuTrigger = ({
    isMenuOpen,
    handleMenuOpen,
    viewport,
}: {
    isMenuOpen: boolean;
    handleMenuOpen: (isOpen: boolean) => void;
    viewport: BreakpointState;
}) => {
    return (
        <>
            {!isMenuOpen && (
                <article
                    className={styles.menuLink}
                    aria-controls="menu"
                    aria-expanded={isMenuOpen}
                    onClick={() => handleMenuOpen(true)}
                >
                    <img
                        src={hamburgerIcon}
                        alt="Hamburger navigation icon"
                        className={styles.headerSvg}
                    />
                    {!viewport.belowMobile && <span>{t`Menu`}</span>}
                </article>
            )}
            {isMenuOpen && (
                <article
                    className={styles.closeLink}
                    aria-controls="menu"
                    aria-expanded={isMenuOpen}
                    onClick={() => handleMenuOpen(false)}
                >
                    <SVG
                        className={styles.headerSvg}
                        src={closeIcon}
                        title="Menu close icon"
                    />
                    {!viewport.belowMobile && <span>{t`Close`}</span>}
                </article>
            )}
        </>
    );
};

const Menu = memo(
    ({
        rich_navigation,
        nav_features,
        nav_list,
        viewport,
        menuRef,
        isMenuOpen,
    }: IMenu) => {
        const [shouldRender, setShouldRender] = useState(false);

        useEffect(() => {
            if (isMenuOpen) {
                // As soon as we get a true value, ensure the menu is in the DOM
                setShouldRender(true);
            }

            // Close the menu safely in no animation environments
            if (!isMenuOpen && !viewport.belowMobile) {
                setShouldRender(false);
            }
        }, [isMenuOpen]);

        const onAnimationEnd = () => {
            if (!isMenuOpen) {
                // Once the closing animation ends, remove the element from the DOM
                setShouldRender(false);
            }
        };

        const menuClasses = classNames([
            ["menu", true],
            [styles.menu, true],
            [styles.richNavigationActive, !!rich_navigation],
            [styles.opening, isMenuOpen],
            [styles.closing, !isMenuOpen],
        ]);

        return (
            <>
                {shouldRender && (
                    <section
                        ref={menuRef}
                        className={menuClasses}
                        aria-label="Site Menu"
                        role="dialog"
                        aria-modal="true"
                        onAnimationEnd={onAnimationEnd}
                    >
                        {nav_features && (
                            <NavFeature nav_features={nav_features} />
                        )}

                        {nav_list && <NavList nav_list={nav_list} />}

                        {/** For now, RichNavigation only works in mobile browsers, it can be changed upon request */}
                        {rich_navigation && viewport.belowMobile && (
                            <nav id="mobile-panel" className="rich-navigation">
                                <RichNavigation
                                    openMobileContentDefaultState={true}
                                    {...rich_navigation}
                                />
                                <ValueProps
                                    value_props={rich_navigation.value_props}
                                    enableValuePropsOnMobile
                                />
                            </nav>
                        )}
                    </section>
                )}
            </>
        );
    },
);

const BasketMenuIconContainer = memo(
    ({
        basketURL,
        checkoutURL,
        isPageInsideIFrame,
        enableBasketPrequalSDK,
        isSSR,
    }: IBasketMenuIconProps) => (
        <BasketMenuIcon
            basketURL={basketURL}
            checkoutURL={checkoutURL}
            isPageInsideIFrame={isPageInsideIFrame}
            basketLineVariant={BasketLineVariant.MINIMAL_ENHANCED}
            buildPromoComponents={(closeModal) => {
                if (!enableBasketPrequalSDK) {
                    return null;
                }
                return (
                    <Provider store={store}>
                        <FinancingModalTriggerMinicart
                            closeModal={closeModal}
                        />
                    </Provider>
                );
            }}
            isSSR={isSSR}
        />
    ),
);

export const Template = (props: templates.SiteHeaderTemplate) => {
    const isSSR = useIsSSR();
    const viewport = useCurrentBreakpoint();
    const menuRef = useRef<HTMLElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { rich_navigation, nav_list, nav_feature } = props;

    const homeURL = isSSR ? isoWebPageURL.wrap("/") : urls.pageURL("home");
    const basketURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("basket-summary");
    const checkoutURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("checkout-index");
    const favoritesURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("favorites-link");
    const findARetailerURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("find-a-retailer");
    const isPageInsideIFrame = isSSR ? false : isPageInsideIFrameCheck();
    const enableBasketPrequalSDK = isSSR
        ? false
        : strToBool(getPageSetting("show-prequal-cart"));

    const basketMenuIconProps = {
        basketURL,
        checkoutURL,
        isPageInsideIFrame,
        enableBasketPrequalSDK,
        isSSR,
    };

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const outsideClickEvent = e.target as Node;
            // If the clicked element is not inside the dropdown, close it
            if (
                menuRef.current &&
                !menuRef.current.contains(outsideClickEvent)
            ) {
                handleMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Including legacy EventListener code during the ongoing SiteHeaderTemplate refactor
    const includeChangesAfterClickEvent = (isOpen: boolean) => {
        const zIndexValue = isOpen ? "200" : "";
        const header = document.querySelector<HTMLElement>("body > header");
        const footer = document.querySelector<HTMLElement>("body > footer");
        if (header) {
            header.style.zIndex = zIndexValue;
        }
        if (footer && viewport.belowMobile) {
            footer.style.zIndex = zIndexValue;
        }
    };

    const lockBodyOnMenuOpen = (isOpen: boolean) => {
        if (isOpen && !isSSR) {
            // disable body
            document.body.classList.remove("overflow--initial");
            document.body.classList.add("overflow--y-hidden");
        } else {
            document.body.classList.add("overflow--initial");
            document.body.classList.remove("overflow--y-hidden");
        }
    };

    const handleMenuOpen = (isOpen: boolean) => {
        setIsMenuOpen(isOpen);
        includeChangesAfterClickEvent(isOpen);
        lockBodyOnMenuOpen(isOpen);
    };

    return (
        <header className={rich_navigation ? "rich-navigation__header" : ""}>
            <a className="screen-reader-skip" href="#main-content">
                {t`Skip to main content`}
            </a>
            <nav className="header-links">
                <MenuTrigger
                    isMenuOpen={isMenuOpen}
                    handleMenuOpen={handleMenuOpen}
                    viewport={viewport}
                />

                <Menu
                    rich_navigation={rich_navigation}
                    nav_features={nav_feature?.nav_features}
                    nav_list={nav_list}
                    viewport={viewport}
                    menuRef={menuRef}
                    isMenuOpen={isMenuOpen}
                />

                <Link
                    className="logo-link"
                    href={homeURL}
                    title="Return to Stearns &amp; Foster Home Page"
                    target="_top"
                >
                    <SVG
                        className={concatClassNames([
                            styles.logo,
                            styles.headerSvg,
                        ])}
                        src={brandLogo}
                        title="Stearns &amp; Foster Logo"
                    />
                </Link>
                <div className="header-icons">
                    {!config.get("ENABLE_ECOM") && (
                        <Provider store={store}>
                            <Link
                                className={`favorites-icon ${styles.favoritesIcon}`}
                                href={favoritesURL}
                                title="Go to my favorites page"
                                target="_top"
                            >
                                <FavoritesIcon />
                            </Link>
                        </Provider>
                    )}

                    <Link
                        className="retail-locator-link"
                        href={findARetailerURL}
                        title="Find a Retailer"
                        target="_top"
                    >
                        <span className="ada-screenreader-only">
                            {t`Nearest retail locations`}
                        </span>
                        <img
                            src={pinIcon}
                            alt="Pin icon"
                            className={styles.headerSvg}
                        />
                        {!viewport.belowMobile && (
                            <Provider store={store}>
                                <RetailLocatorStoreCount type={"bare-count"} />
                            </Provider>
                        )}
                    </Link>

                    {config.get("ENABLE_ECOM") && (
                        <Provider store={store}>
                            <AccountMenu />
                            <BasketMenuIconContainer {...basketMenuIconProps} />
                        </Provider>
                    )}
                </div>
            </nav>
        </header>
    );
};
