import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { BreakPoint } from "tsi-common-react/src/models/screen.interfaces";
import { getViewportBreakpoint } from "tsi-common-react/src/utils/detectMobile";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { trapMenuFocus } from "tsi-common-react/src/utils/keyboardFocus";

const header = document.querySelector<HTMLElement>("body > header");
const footer = document.querySelector<HTMLElement>("body > footer");

// NOTE: These DOM manipulations can be deleted after the QA process of
// SSR migration of SiteHeader Template

const isMobileWidth = () => {
    const currentBreakpoint = getViewportBreakpoint();
    return currentBreakpoint < BreakPoint.SMALL;
};

const closeMenu = (menu: HTMLElement) => {
    menu.classList.remove("open");
    if (isMobileWidth()) {
        menu.classList.add("closing");
        menu.addEventListener(
            "animationend",
            () => {
                menu.classList.remove("closing");
                menu.classList.add("-is-hidden");
            },
            { once: true },
        );
        return;
    }
    menu.classList.add("-is-hidden");
    clearAllBodyScrollLocks();
};

const registerEventListeners_MenuLink = () => {
    const menuLink = document.getElementById("menu-link");
    const sfHeader = document.getElementById("sf-header");

    if (menuLink) {
        menuLink.addEventListener("click", function (event) {
            event.preventDefault();
            this.classList.toggle("-is-hidden");
            sfHeader?.classList.toggle("-is-open");
            if (header) {
                header.style.zIndex = "200";
            }
            if (footer && isMobileWidth()) {
                footer.style.zIndex = "200";
            }

            const closeLink = document.getElementById("close-link");
            if (closeLink) {
                closeLink.classList.toggle("-is-hidden");
                closeLink.focus();
                closeLink.setAttribute("aria-expanded", "true");
                menuLink.setAttribute("aria-expanded", "true");
            }

            const menu = document.getElementById("menu");
            if (menu) {
                menu.classList.add("open");
                menu.classList.remove("-is-hidden");
                menu.addEventListener("click", (menuEvent) => {
                    menuEvent.stopPropagation();
                });
                if (isMobileWidth()) {
                    menu.classList.add("opening");
                    menu.addEventListener(
                        "animationend",
                        () => {
                            menu.classList.remove("opening");
                        },
                        { once: true },
                    );
                    return;
                }

                if (closeLink) {
                    trapMenuFocus(menu, closeLink);
                }
                disableBodyScroll(menu);
            }

            // Init and close eventListeners for Rich Navigation Menu
            // In order to close it with outside click
            if (isSiteMenuOpen()) {
                initSiteMenuListener();
                return;
            }
            closeSiteMenuListener();
        });
    }
};

const registerEventListeners_CloseLink = () => {
    const closeLink = document.getElementById("close-link");
    const sfHeader = document.getElementById("sf-header");
    if (closeLink) {
        closeLink.addEventListener("click", function (event) {
            event.preventDefault();
            this.classList.toggle("-is-hidden");
            sfHeader?.classList.toggle("-is-open");
            if (header) {
                header.style.zIndex = "";
            }
            if (footer) {
                footer.style.zIndex = "";
            }

            const menuLink = document.getElementById("menu-link");
            if (menuLink) {
                menuLink.classList.toggle("-is-hidden");
                menuLink.focus();
                closeLink.setAttribute("aria-expanded", "false");
                menuLink.setAttribute("aria-expanded", "false");
            }

            const menu = document.getElementById("menu");
            if (menu) {
                closeMenu(menu);
            }
        });
    }
};

const registerEventListeners_Overlay = () => {
    const overlay = document.getElementById("overlay");
    if (overlay) {
        overlay.addEventListener("click", (event) => {
            event.preventDefault();

            const menuLink = document.getElementById("close-link");
            if (menuLink) {
                menuLink.classList.toggle("-is-hidden");
            }

            const closeLink = document.getElementById("menu-link");
            if (closeLink) {
                closeLink.classList.toggle("-is-hidden");
            }

            const menu = document.getElementById("menu");
            if (menu) {
                closeMenu(menu);
            }
        });
    }
};

export const isSiteMenuOpen = () => {
    const menu = document.getElementById("menu");
    const isHidden = menu?.classList.contains("-is-hidden");
    return !isHidden;
};

export const initSiteMenuListener = () => {
    document.body.classList.add("menu-open");
    const bodyWithModal = document.querySelector<HTMLElement>(".menu-open");
    if (bodyWithModal) {
        bodyWithModal.addEventListener("click", closeSiteMenuOnBodyClick);
    }
};

export const closeSiteMenuListener = () => {
    const menu = document.getElementById("menu");
    if (menu) {
        closeMenu(menu);
        const hiddenClass = "-is-hidden";
        const menuLink = document.querySelector<HTMLElement>("#menu-link");
        const closeLink = document.querySelector<HTMLElement>("#close-link");
        closeLink?.classList.add(hiddenClass);
        menuLink?.classList.remove(hiddenClass);
        document.body.removeEventListener("click", closeSiteMenuOnBodyClick);
    }
};

const closeSiteMenuOnBodyClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (document.getElementById("menu-link")?.contains(target)) {
        return false;
    }
    event.preventDefault();
    if (isSiteMenuOpen()) {
        closeSiteMenuListener();
    }
    return true;
};

onDOMContentLoaded.on(() => {
    registerEventListeners_MenuLink();
    registerEventListeners_CloseLink();
    registerEventListeners_Overlay();
});
