import React from "react";
import { Provider } from "react-redux";
import { registerCascades } from "tsi-common-react/src/apps/retail/cascades";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { urls } from "tsi-common-react/src/utils/urls";

import { store } from "../store";

(async () => {
    // Register store cascades
    registerCascades(store, undefined, {
        has_slot_stearns_foster: "Y",
        distance: 50,
    });

    dynamicPlaceComponent('[data-place-react="retail-cards"]', async () => {
        const { RetailCards } = await import(
            "tsi-common-react/src/apps/retail/containers/RetailCards"
        );
        return (
            <Provider store={store}>
                <RetailCards
                    retailLocatorLink={urls.pageURL("find-a-retailer")}
                />
            </Provider>
        );
    }).catch(console.error);

    dynamicPlaceComponent('[data-place-react="retail-locator"]', async () => {
        const { RetailLocator } = await import("./containers/RetailLocator");
        return (
            <Provider store={store}>
                <RetailLocator />
            </Provider>
        );
    }).catch(console.error);

    dynamicPlaceComponent(
        '[data-place-react="retail-locator-store-count"]',
        async (elem) => {
            const { RetailLocatorStoreCount } = await import(
                "./containers/RetailLocatorStoreCount"
            );
            return (
                <Provider store={store}>
                    <RetailLocatorStoreCount
                        type={
                            elem.dataset.type === "sentence"
                                ? "sentence"
                                : "bare-count"
                        }
                    />
                </Provider>
            );
        },
    ).catch(console.error);
})();
